<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Item</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="itemInsert">
              <div class="card-body">
                <text-input
                  :value.sync="form.name"
                  label="Name"
                  :error="errors.name"
                ></text-input>
                <div class="form-group">
                  <label>Venue</label>
                  <select class="custom-select" v-model="form.venue_id">
                    <option selected value="">Open this select menu</option>
                    <option v-for="v in venues" :key="v.id" :value="v.id">{{
                      v.title
                    }}</option>
                  </select>
                  <small class="text-danger" v-if="errors.venue_id">{{
                    errors.venue_id[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import { apiGet } from "../../services/api";

export default {
  name: "FormItem",
  data() {
    return {
      name: "Form Item",
      form: {
        name: "",
        venue_id: "",
      },
      venues: [],
      errors: {},
      isSubmitting: false,
    };
  },
  mounted() {
    this.getVenue();
  },
  methods: {
    onImageSelected(event) {
      this.form.image = event.target.files[0];
    },
    getVenue() {
      apiGet("venue").then(({ data }) => {
        this.venues = data.data;
      });
    },
    itemInsert() {
      this.isSubmitting = true;
      axios
        .post(this.$store.state.api + "item/store", this.form, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => {
          if (res.data.code == 500) {
            swal("Error!", res.data.message, "error");
          } else {
            this.$noty.success("Your item has been saved!");
            this.$router.push({ name: "Item" });
          }
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
